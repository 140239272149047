import { LoadableAfterPaint } from '@confluence/loadable';

import { RendererContentStatePlaceholder } from './RendererContentState/RendererContentStatePlaceholder';

export const LazyEditorContentStateComponent = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-EditorContentStateEditorContentStateComponent" */ './EditorContentState/EditorContentStateComponent'
			)
		).EditorContentStateComponent;
	},
});

export const LazyRendererContentStateComponent = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-RendererContentStateRendererContentStateComponent" */ './RendererContentState/RendererContentStateComponent'
			)
		).RendererContentStateComponent;
	},
	loading: RendererContentStatePlaceholder,
});

export const LazyRendererContentStateWithSpotlight = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-RendererContentStateRendererContentStateComponent" */ './RendererContentState/RendererContentStateWithSpotlight'
			)
		).RendererContentStateWithSpotlight;
	},
	loading: RendererContentStatePlaceholder,
});
