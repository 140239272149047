import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import {
	QuickReloadSubscriber,
	ReloadType,
} from '@confluence/quick-reload/entry-points/subscription';

import { LazyRendererContentStateWithSpotlight } from '../lazyLoadables';

import type { RendererContentStateProps } from './types';
import type {
	RendererContentState as RendererContentStateType,
	RendererContentStateVariables,
} from './__types__/RendererContentStateQuery';
import { GraphQLDateFormat } from './__types__/RendererContentStateQuery';
import { RendererContentStateQuery } from './RendererContentStateQuery.graphql';

export const RendererContentStateImpl = ({
	contentId,
	isPagePreview,
	hasSeparator,
}: RendererContentStateProps) => {
	const { data, refetch } = useQuery<RendererContentStateType, RendererContentStateVariables>(
		RendererContentStateQuery,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				contentId,
				isDraft: isPagePreview, // if isPagePreview => true, else false
				format: GraphQLDateFormat.USER_FRIENDLY,
			},
		},
	);

	if (!data) {
		return null;
	}

	const lastUpdatedInfo = data?.singleContent?.contentStateLastUpdated?.value;
	const contentState = data?.singleContent?.contentState;

	return (
		<Fragment>
			{contentState && (
				<LazyRendererContentStateWithSpotlight
					contentId={contentId}
					contentState={contentState}
					lastUpdatedInfo={lastUpdatedInfo}
					isPagePreview={isPagePreview}
					hasSeparator={hasSeparator}
				/>
			)}
			<QuickReloadSubscriber name="content-state" types={[ReloadType.content]} reload={refetch} />
		</Fragment>
	);
};

export const RendererContentState = withTransparentErrorBoundary({
	attribution: Attribution.PAGE_EXTENSIONS,
})(RendererContentStateImpl);
