import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, xcss, Flex } from '@atlaskit/primitives';

import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import type { RendererContentStatePlaceholderProps } from './types';

const loadingPlaceholderBaseStyles = xcss({
	cursor: 'pointer',
	minHeight: '32px',
	borderRadius: '3px',
	paddingRight: 'space.100',
});

const placeHolderBackroundColorStyles = xcss({
	backgroundColor: 'color.background.neutral',
});

const subtlePlaceholderBackgroundColorStyles = xcss({
	backgroundColor: 'utility.elevation.surface.current',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStateNamePlaceholderWrapper = styled.div({
	fontWeight: 500,
	visibility: 'hidden',
});

const classicContentStateColorPlaceholderStyles = xcss({
	borderRadius: '12px',
	height: '12px',
	width: '12px',
	marginTop: 'space.0',
	marginBottom: 'space.0',
	marginLeft: 'space.100',
	marginRight: 'space.100',
	backgroundColor: 'color.background.neutral',
});

const subtleContentStateColorPlaceholderStyles = xcss({
	borderRadius: '10px',
	height: '10px',
	width: '10px',
	marginLeft: 'space.050',
	marginRight: 'space.075',
	backgroundColor: 'color.background.neutral',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBorder = styled.div({
	borderLeft: `2px solid ${token('color.border.accent.gray', 'rgba(107, 119, 140, 0.3)')}`,
	display: 'inline-block',
	width: '1px',
	height: '16px',
	margin: `0 ${token('space.050', '4px')} 0 10px`,
	alignSelf: 'center',
});

const flexWrapperStyles = xcss({
	marginLeft: 'space.075',
	marginRight: 'space.075',
});

export const RendererContentStatePlaceholder = ({
	contentState,
	isPagePreview,
}: RendererContentStatePlaceholderProps) => {
	const isLivePage = useIsLivePage();
	const isNewLivePageBylineFeatureFlagEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.new-live-pages-byline',
	);
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const isNewLivePageByline =
		isLivePage && isCustomSitesPageTitleFFOn && isNewLivePageBylineFeatureFlagEnabled;

	const contentStateLoadingPlaceholderStyles = [
		loadingPlaceholderBaseStyles,
		isNewLivePageByline ? subtlePlaceholderBackgroundColorStyles : placeHolderBackroundColorStyles,
	];
	const contentStateColorPlaceholderStyles = [
		isNewLivePageByline
			? subtleContentStateColorPlaceholderStyles
			: classicContentStateColorPlaceholderStyles,
	];

	return (
		<Flex xcss={flexWrapperStyles}>
			<Flex
				as="li"
				alignItems="center"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={contentStateLoadingPlaceholderStyles}
				testId={
					isNewLivePageByline
						? 'subtle-renderer-content-state-placeholder'
						: 'renderer-content-state-placeholder'
				}
			>
				<Box
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
					xcss={contentStateColorPlaceholderStyles}
					testId={
						isNewLivePageByline
							? 'subtle-renderer-content-state-color-placeholder'
							: 'renderer-content-state-color-placeholder'
					}
				/>
				<ContentStateNamePlaceholderWrapper>
					{contentState?.name}
				</ContentStateNamePlaceholderWrapper>
			</Flex>
			{!isPagePreview && <StyledBorder data-testid="placeholder-vertical-line" />}
		</Flex>
	);
};
